import type { SelectOption, SelectOptionChildren, UseOptionsListOptions } from '../types';

const optionIdPrefix = 'app-option';
const optionIdPrefixSplit = '__';

export function useOptionsList(options: UseOptionsListOptions) {
  const { props, emit } = options;

  const containerRef = ref<HTMLDivElement>();
  const optionsGroupLabelsRef = ref<HTMLDivElement[]>([]);
  const optionRef = ref<HTMLDivElement[]>([]);
  const observeElement = ref<HTMLDivElement>();
  const customHeadRef = ref<HTMLDivElement>();
  const emptyStateRef = ref<HTMLDivElement>();
  const focusValue = ref<SelectOptionChildren | null>();
  const focusCount = ref(-1);
  const groupStepHeight = ref(0);
  const isArrowNavigationListenersSet = ref(false);
  const unobserveScrollToEnd = shallowRef<(target: Element) => void>();

  const emptyStateVisible = computed(() => props.emptyState && props.options.length <= 0);
  const containerHeight = computed(() => {
    const labels = unref(optionsGroupLabelsRef);
    const options = unref(optionRef);
    const customHeadEl = unref(customHeadRef);

    let height = 0;

    if (options && options.length) {
      height = options[0].offsetHeight * props.rows;

      if (labels && labels.length) {
        height += labels[0]?.offsetHeight;
      }
    }

    if (customHeadEl) {
      height += customHeadEl.offsetHeight;
    }

    return height;
  });
  const listStyle = computed(() => {
    const height = !emptyStateVisible.value ? `${containerHeight.value}px` : 'unset';
    return { 'max-height': height };
  });
  const commonOptions = computed(() => props.options.flatMap(item => item.children));

  watch(() => props.isInFocus, (value) => {
    if (value) {
      isArrowNavigationListenersSet.value = true;
      setNavigationKeyListener();
    } else {
      focusValue.value = Array.isArray(props.choseValue) ? props.choseValue[0] : props.choseValue;
      if (!focusValue.value) {
        focusCount.value = -1;
      }

      if (isArrowNavigationListenersSet.value) {
        removeNavigationKeyListener();
      }
    }
  });

  function setFocusOnHover(option: SelectOptionChildren | null) {
    focusValue.value = option;
    if (!option) {
      focusCount.value = -1;
    }
  }

  function scrollOptionsList(eventType: 'up' | 'down') {
    const optionsEl = unref(containerRef);
    const labels = unref(optionsGroupLabelsRef);

    const currentElement = unref(optionRef)[focusCount.value];

    const parentRect = optionsEl?.getBoundingClientRect();
    const children = unref(optionRef);

    const innerChildren = children.filter((child) => {
      const childRect = child.getBoundingClientRect();
      if (parentRect) {
        return (
          childRect.top >= parentRect.top
          && childRect.bottom <= parentRect.bottom
        );
      }
      return false;
    });

    const has = innerChildren.findIndex((item) => {
      const id = +item.id.split(optionIdPrefixSplit)[1];
      return id === focusCount.value;
    });

    const eventTypes = {
      up: -currentElement.offsetHeight + groupStepHeight.value,
      down: currentElement.offsetHeight + groupStepHeight.value,
    };
    if (has < 0) {
      optionsEl?.scrollBy(0, eventTypes[eventType]);
    }

    if (!children[focusCount.value].nextElementSibling) {
      groupStepHeight.value = labels[0]?.offsetHeight;
    } else {
      groupStepHeight.value = 0;
    }
  }

  function scrollTo(eventType: 'up' | 'down') {
    const optionsEl = unref(containerRef);

    const eventTypes = {
      upHandler() {
        if (optionsEl) {
          optionsEl.scrollTop = 0;
        }
      },

      downHandler() {
        if (optionsEl) {
          optionsEl.scrollTop = optionsEl.scrollHeight;
        }
      },
    };

    eventTypes[`${eventType}Handler`]();
  }

  function selectOptionOnKeyDown(e: Event & KeyboardEvent) {
    const event = e.key.replace('Arrow', '').toLowerCase();

    const optionsCount = commonOptions.value.length - 1;

    if (focusValue.value) {
      const has = commonOptions.value.findIndex(item => item.name === focusValue.value?.name);
      focusCount.value = has;
    }

    const eventTypes = {
      upHandler() {
        focusCount.value -= 1;

        if (focusCount.value < 0) {
          focusCount.value = optionsCount;
          scrollTo('down');
        } else {
          scrollOptionsList('up');
        }
      },

      downHandler() {
        if (focusCount.value === optionsCount) {
          focusCount.value = 0;
          scrollTo('up');
        } else {
          focusCount.value += 1;
        }
        scrollOptionsList('down');
      },
    };

    if (event === 'up' || event === 'down') {
      eventTypes[`${event}Handler`]();
    }

    if (focusCount.value >= 0) {
      focusValue.value = commonOptions.value[focusCount.value];
      if (event === 'enter') {
        emit('onOptionSelect', focusValue.value);
      }
    }
  }

  function getOptionIndex(optionIndex: number, groupIndex: number) {
    if (groupIndex === 0) {
      return `${optionIdPrefix}${optionIdPrefixSplit}${optionIndex}`;
    }

    const index = optionIndex + props.options[groupIndex - 1].children.length;
    return `${optionIdPrefix}${optionIdPrefixSplit}${index}`;
  }

  function observeScrolledToEnd() {
    if (props.observeScrollToEnd) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          emit('onScrolledToEnd');
        }
      });

      const element = unref(observeElement);

      if (element) {
        observer.observe(element);
        unobserveScrollToEnd.value = observer.unobserve;
      }
    }
  }

  // function unobserveScrolledToEnd() {
  //   if (unobserveScrollToEnd.value) {
  //     const element = unref(observeElement);
  //     if (element) {
  //       unobserveScrollToEnd.value?.(element);
  //     }
  //   }
  // }

  function preventKeyboardScroll(e: Event & KeyboardEvent) {
    const keys = ['ArrowDown', 'ArrowUp'];
    if (keys.includes(e.key)) {
      e.preventDefault();
    }
  }

  function setKeyListeners() {
    if (props.isInFocus) {
      setNavigationKeyListener();
      isArrowNavigationListenersSet.value = true;
    }
    preventDefaultContainerKeydownEvents();
  }

  function optionHasGroupLabel(option: SelectOption): boolean {
    return option.type === 'group' && !!option.label;
  }

  function removeKeyListeners() {
    if (isArrowNavigationListenersSet.value) {
      removeNavigationKeyListener();
    }

    removePreventDefaultContainerKeydownEvent();
  }

  function setNavigationKeyListener() {
    window.addEventListener('keydown', selectOptionOnKeyDown);
  }

  function removeNavigationKeyListener() {
    window.removeEventListener('keydown', selectOptionOnKeyDown);
  }

  function preventDefaultContainerKeydownEvents() {
    document.addEventListener('keydown', preventKeyboardScroll);
  }

  function removePreventDefaultContainerKeydownEvent() {
    document.removeEventListener('keydown', preventKeyboardScroll);
  }

  onMounted(() => {
    setKeyListeners();
    observeScrolledToEnd();
  });

  onBeforeUnmount(() => {
    removeKeyListeners();
    // unobserveScrolledToEnd();
  });

  return {
    containerRef,
    optionsGroupLabelsRef,
    optionRef,
    customHeadRef,
    observeElement,
    emptyStateRef,
    focusValue,
    emptyStateVisible,
    listStyle,
    optionHasGroupLabel,
    setFocusOnHover,
    getOptionIndex,
  };
}
